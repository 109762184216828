import { Children, cloneElement, useEffect, useRef } from 'react'
import classNames from 'classnames'
import styles from './StickyCards.module.scss'
import Card from './components/Card/Card'
import Header from './components/Header/Header'
import { useScrollDirection } from 'hooks/useScrollDirection'

const StickyCards = ({ children }) => {
  // Hooks
  const scrollDirection = useScrollDirection({ disabled: false })

  // Refs
  const rootEl = useRef<HTMLElement>(null)
  const headerEl = useRef<HTMLDivElement>(null)

  // Elements
  let cardIndex = 0
  const header = Children.map(children, (child) => (child.type?.displayName === 'Header' ? child : null))
  const cards = Children.map(children, (child) => {
    if (child.type?.displayName === 'Card') {
      return cloneElement(child, { _childIndex: cardIndex++ })
    }
    return null
  })

  // CSS variables - Offset
  useEffect(() => {
    if (rootEl.current !== null) {
      rootEl.current.style.setProperty('--js-root-offset', cards.length + 'em')
    }
  }, [rootEl.current, cards.length])

  // CSS variables - Header Height
  useEffect(() => {
    let resizeObserver: ResizeObserver
    if (rootEl.current !== null && headerEl.current !== null) {
      // initial calculation
      const headerElHeight = headerEl.current.getBoundingClientRect().height
      rootEl.current?.style.setProperty('--js-header-height', headerElHeight + 'px')

      // init observer
      resizeObserver = new ResizeObserver((entries) => {
        const headerElEntry = entries[0]
        rootEl.current?.style.setProperty('--js-header-height', headerElEntry.contentRect.height + 'px')
      })
      resizeObserver.observe(headerEl.current)
    }

    return () => resizeObserver?.disconnect()
  }, [rootEl.current, headerEl.current])

  return (
    <section ref={rootEl} className={classNames('section container', styles.root, scrollDirection === 'down' && styles.scrollingDown)}>
      {!!header.length && (
        <div ref={headerEl} className={styles.header}>
          {header}
        </div>
      )}
      {!!cards.length && <ul className={styles.cards}>{cards}</ul>}
    </section>
  )
}

StickyCards.Header = Header
StickyCards.Card = Card
export default StickyCards
