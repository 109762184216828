import classNames from 'classnames'
import styles from './FaqSection.module.scss'
import { Button } from '@boltenergy-be/design-system'
import { useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { FaqSectionProps } from './types'
import { Accordion, Heading } from '@boltenergy-be/design-system'
import parse from 'html-react-parser'

const FaqSection = ({ questions, title, id }: FaqSectionProps) => {
  // i18n
  const { t } = useTranslation('common')

  // Local state
  const [faqItemsToShow, setFaqItemsToShow] = useState<number>(questions.length === 6 ? 6 : 5)

  const sortedQuestions = useMemo(
    () => questions.sort((a, b) => (b?.attributes.isFeatured === a?.attributes.isFeatured ? 0 : a.attributes.isFeatured ? -1 : 1)),
    [questions]
  )

  return (
    <section className={classNames('section is-dark', styles['faq-section'])}>
      <div id={id} className="container">
        <Heading as="h2" variant="h3">
          {title || t('faq.title', 'Veelgestelde vragen')}
        </Heading>

        <Accordion
          className={styles['faq-container']}
          items={sortedQuestions
            .slice(0, faqItemsToShow)
            .map((faqQuestion) => ({ title: faqQuestion.attributes.question, children: parse(faqQuestion.attributes.answer) }))}
        />
        {faqItemsToShow < sortedQuestions.length && (
          <div className={styles['button-container']}>
            <Button variant="secondary" onClick={() => setFaqItemsToShow(faqItemsToShow + 4)}>
              {t('showMore', 'Toon meer')}
            </Button>
          </div>
        )}
      </div>
    </section>
  )
}

export default FaqSection
