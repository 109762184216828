export enum StickyCardShapes {
  HOUSE = 'house',
  OVERFLOWING = 'overflowing'
}

export enum StickyCardColors {
  YELLOW = 'yellow',
  PURPLE = 'purple',
  BLUE = 'blue',
  ORANGE = 'orange'
}

export type StickyCardProps = {
  _childIndex?: number
  title: string
  description: string
  image?: {
    alt: string
    publicId: string // cloudinary public ID
    shape?: StickyCardShapes
  }
  color: StickyCardColors
}
