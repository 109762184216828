import styles from '../../Referral.module.scss'
import ReferralForm from 'features/referral/components/ReferralForm/ReferralForm'
import { useTranslation } from 'next-i18next'
import parse from 'html-react-parser'
import HowItWorks from 'features/referral/components/HowItWorks/HowItWorks'
import BackToTop from 'features/referral/components/BackToTop/BackToTop'
import StickyOffering from 'components/sections/bolt-offering/sticky-offering/StickyOffering'
import Hero from 'components/sections/hero/Hero'
import FaqSection from 'components/faq/faq-section/FaqSection'
import Tag from 'components/tag/Tag'
import { PredefinedTagColors } from 'components/tag/types'
import Drawer from 'components/drawer/Drawer'
import { useState } from 'react'
import Icon from 'components/icon/Icon'
import SplitSection from 'components/split-section/SplitSection'
import { Button, Heading } from '@boltenergy-be/design-system'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import Img from 'components/img/Img'
import { COMMUNITY_SALES_DEFAULT_REDUCTION, DAYJS_THRESHOLDS } from 'features/referral/constants'
import DurationTimeStamp from 'components/DurationTimeStamp/DurationTimeStamp'
import { ReferralProps } from 'features/referral/types'
import { BlocksContent, BlocksRenderer } from '@strapi/blocks-react-renderer'

dayjs.extend(relativeTime, {
  thresholds: DAYJS_THRESHOLDS,
  rounding: Math.floor
})

const Referral = ({ activeAction, faqQuestions }: ReferralProps) => {
  // I18N
  const { t } = useTranslation('referral')

  // Local state
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

  // Constants
  const amount = activeAction?.attributes?.reduction || COMMUNITY_SALES_DEFAULT_REDUCTION

  return (
    <>
      <main>
        {/* HERO Intro */}
        <Hero
          image={{
            alt: t('referral.hero.title', { amount }),
            customClass: styles.heroImage,
            publicId:
              activeAction?.attributes?.websiteHero?.image?.data?.attributes?.provider_metadata?.public_id ||
              'website/community-sales/rw-action'
          }}
          video={activeAction?.attributes?.websiteHero?.video?.data?.attributes?.provider_metadata?.public_id}
        >
          {!!activeAction && (
            <Tag color={PredefinedTagColors.YELLOW}>
              <Icon name="action" size={16} />
              {t('activeAction.badge', 'Actie')}
            </Tag>
          )}
          <Hero.Title
            className={styles['hero-title']}
            value={activeAction?.attributes?.websiteHero?.title || parse(t('referral.hero.title', { amount }))}
          />
          {activeAction?.attributes?.websiteHero?.description ? (
            <BlocksRenderer content={activeAction?.attributes?.websiteHero?.description} />
          ) : (
            <Hero.Paragraph value={parse(t('referral.hero.paragraph'))} />
          )}

          {/* REFERRAL Form*/}
          <ReferralForm {...{ activeAction }} />
        </Hero>

        {/*	How it works */}
        <HowItWorks reward={activeAction?.attributes?.reduction || amount} />

        {/* HERO Action */}
        {!!activeAction && (
          <SplitSection className={styles['action-section']}>
            <SplitSection.Left>
              <div>
                <span className={styles.timer} suppressHydrationWarning>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.99984 0.833252C5.05229 0.833252 1.0415 4.84404 1.0415 9.79158C1.0415 14.7392 5.05229 18.7499 9.99984 18.7499C14.9474 18.7499 18.9582 14.7392 18.9582 9.79158C18.9582 4.84404 14.9474 0.833252 9.99984 0.833252ZM10.8332 5.83325C10.8332 5.37301 10.4601 4.99992 9.99984 4.99992C9.5396 4.99992 9.1665 5.37301 9.1665 5.83325V9.99992C9.1665 10.269 9.29648 10.5216 9.51547 10.678L12.4321 12.7614C12.8067 13.0289 13.3271 12.9421 13.5946 12.5676C13.8621 12.1931 13.7754 11.6726 13.4009 11.4051L10.8332 9.57107V5.83325Z"
                      fill="currentColor"
                    />
                  </svg>
                  <DurationTimeStamp endDate={activeAction?.attributes?.end} />
                </span>
                <Heading as="h2">{activeAction?.attributes?.shortContent?.title}</Heading>
              </div>

              <BlocksRenderer content={activeAction?.attributes?.shortContent?.description as BlocksContent} />

              {/* More info */}
              <Button onClick={() => setDrawerOpen(true)} className={styles['more-info-btn']} variant="tertiary">
                {t('common:moreInfo')}
              </Button>
            </SplitSection.Left>
            <SplitSection.Right>
              <Img publicId={activeAction?.attributes?.image?.data?.attributes?.provider_metadata?.public_id} fill alt="" />
            </SplitSection.Right>
          </SplitSection>
        )}

        {/* Sticky Cards */}
        <StickyOffering title={t('referral.convince.title')} paragraph={t('referral.convince.description')} />

        {/* Back to Top */}
        <BackToTop />

        {/* FAQ */}
        {!!faqQuestions?.length && <FaqSection questions={faqQuestions} />}
      </main>

      {/* Drawer */}
      {activeAction && (
        <Drawer
          isOpen={drawerOpen}
          onRequestClose={() => setDrawerOpen(false)}
          title={activeAction?.attributes?.conditions?.title}
          contentClassName={styles.drawer}
        >
          <BlocksRenderer
            content={activeAction.attributes.conditions.description as BlocksContent}
            blocks={{
              heading: ({ children, level }) => (
                <Heading as={`h${level}`} variant={level === 1 ? 'h2' : 'h6'}>
                  {children}
                </Heading>
              )
            }}
          />
        </Drawer>
      )}
    </>
  )
}

export default Referral
