export enum BackgroundColors {
  TRANSPARENT = 'transparent',
  SUBDUED = 'subdued',
  BRAND = 'brand',
  PINK = 'pink',
  PURPLE = 'purple',
  VANILLA = 'vanilla',
  YELLOW = 'yellow',
  LIGHT_BLUE = 'lightblue'
}
