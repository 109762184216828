import classNames from 'classnames'
import styles from './BackToTop.module.scss'
import { Button } from '@boltenergy-be/design-system'
import { useTranslation } from 'next-i18next'
import { Heading } from '@boltenergy-be/design-system'

const BackToTop = () => {
  // I18N
  const { t } = useTranslation(['referral'])

  /**
   * Sets focus to the email field
   */
  const setFocusToEmailInput = () => {
    const inputEl: HTMLInputElement = document.querySelector('[data-scrolltarget]')

    // Safari & Firefox don't support smooth scrolling onFocus of an element.
    // Fix to smooth scroll first and then add focus.
    inputEl?.scrollIntoView?.({ behavior: 'smooth', block: 'center' })
    setTimeout(() => inputEl?.focus?.(), 1000)
  }

  return (
    <section className={classNames('container section', styles.root)}>
      <div className={classNames('card', styles.card)}>
        <Heading as="h2" variant="h3" className="mb-200">
          {t('referral.backToTop.title')}
        </Heading>
        <p>{t('referral.backToTop.paragraph')}</p>
        <Button onClick={setFocusToEmailInput}>{t('referral.backToTop.button')}</Button>
      </div>
    </section>
  )
}

export default BackToTop
