import { useEffect, useRef } from 'react'
import styles from './Card.module.scss'
import { House } from 'assets/svg'
import classNames from 'classnames'
import { StickyCardProps } from 'components/sections/sticky-cards/components/Card/types'
import Img from 'components/img/Img'

const Card = ({ _childIndex, title, description, image, color }: StickyCardProps) => {
  // Refs
  const cardEl = useRef(null)

  // CSS variables - translateY
  useEffect(() => {
    if (cardEl.current !== null) {
      cardEl.current.style.setProperty('--js-translate-Y', _childIndex + 'em')
    }
  }, [cardEl.current, _childIndex])

  return (
    <li ref={cardEl} className={classNames(styles.root, styles[color])}>
      <div className={styles.content}>
        <div className={styles.order}>
          <House />
          <strong>{_childIndex + 1}</strong>
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      {!!image && (
        <picture className={classNames({ [styles[image.shape]]: !!image.shape })}>
          <Img
            fill
            style={{
              objectFit: image.shape === 'overflowing' ? 'contain' : 'cover',
              objectPosition: image.shape === 'overflowing' ? 'left' : 'center'
            }}
            publicId={image.publicId}
            alt={image.alt}
          />
        </picture>
      )}
    </li>
  )
}

Card.displayName = 'Card'
export default Card
