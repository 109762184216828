import styles from '../../Referral.module.scss'
import { useTranslation } from 'next-i18next'
import StickyOffering from 'components/sections/bolt-offering/sticky-offering/StickyOffering'
import Hero from 'components/sections/hero/Hero'
import FaqSection from 'components/faq/faq-section/FaqSection'
import { COMMUNITY_SALES_DEFAULT_REDUCTION } from 'features/referral/constants'
import { ReferralProps } from 'features/referral/types'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'

const Referee = ({ activeAction, faqQuestions, referralCode }: ReferralProps) => {
  // I18N
  const { t } = useTranslation('referral')

  // Constants
  const amount = activeAction?.attributes?.reduction || COMMUNITY_SALES_DEFAULT_REDUCTION
  const firstName = referralCode.referrer.firstName

  return (
    <main>
      <Hero
        withSimulationForm
        image={{
          alt: '',
          customClass: styles.heroImage,
          publicId:
            activeAction?.attributes?.websiteHeroReceiver?.image?.data?.attributes?.provider_metadata?.public_id ||
            'website/producers/citibee'
        }}
        video={activeAction?.attributes?.websiteHeroReceiver?.video?.data?.attributes?.provider_metadata?.public_id}
      >
        <Hero.Title
          value={
            activeAction?.attributes?.websiteHeroReceiver?.title ||
            t('referee.hero.title', 'Dankzij {{firstName}} krijg je € {{amount}} korting', {
              firstName,
              amount
            })
          }
          className={styles.heroTitle}
        />
        {activeAction?.attributes?.websiteHeroReceiver?.description ? (
          <BlocksRenderer content={activeAction.attributes.websiteHeroReceiver.description} />
        ) : (
          <Hero.Paragraph
            value={t(
              'referee.hero.paragraph',
              'Word net als {{firstName}} energie-afnemer bij Bolt en krijg € {{amount}} vriendenkorting.',
              {
                firstName,
                amount
              }
            )}
          />
        )}
      </Hero>

      {/* Sticky Cards */}
      <StickyOffering title={t('referee.whyBolt', 'Waarom kiezen voor Bolt?')} />

      {/*	FAQ */}
      {!!faqQuestions?.length && <FaqSection questions={faqQuestions} />}
    </main>
  )
}

export default Referee
