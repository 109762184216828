import { OfferingCard } from 'types/offering'
import { StickyCardColors, StickyCardShapes } from 'components/sections/sticky-cards/components/Card/types'

// placeholders:
// - description: (big colored cards, eg: homepage, community sales)
// - shortDescription: (small cards, eg: pricing page)
// - tag: (advantages, eg: simulation fixed footer)
// - title
export const OFFERING_CARDS: OfferingCard[] = [
  {
    color: StickyCardColors.YELLOW,
    image: {
      alt: '',
      cloudinaryPublicId: 'website/common/offering-durable-power',
      shape: StickyCardShapes.HOUSE
    },
    key: 'durable',
    placeholders: {
      description:
        'The future is now. Via Bolt kan je alleen maar duurzame stroom van lokale energie-opwekkers uit België kopen. Zo weet je waar je stroom vandaan komt en waar je geld naartoe gaat. Je bent dus zeker dat je bijdraagt aan een duurzaam stroomnetwerk.',
      shortDescription: 'Zo weet je waar je stroom vandaan komt en waar je geld naartoe gaat. Duurzaam en transparant.',
      tag: 'Duurzame stroom',
      title: 'Duurzame stroom van een lokale energie-opwekker'
    }
  },
  {
    color: StickyCardColors.PURPLE,
    image: {
      alt: '',
      cloudinaryPublicId: 'website/common/customer-care',
      shape: StickyCardShapes.HOUSE
    },
    key: 'customerCare',
    placeholders: {
      description:
        'Zeg vaarwel tegen anonieme callcenters en hallo tegen ons enthousiast customer care team! Daan, Marianne, Noa en de rest van ons team luisteren en nemen de tijd om jou vragen zo goed mogelijk te beantwoorden.',
      shortDescription:
        'Daan, Marianne, Noa en de rest van ons team luisteren en nemen de tijd om jou vragen zo goed mogelijk te beantwoorden.',
      tag: 'Persoonlijke klantendienst',
      title: 'Een klantendienst die jou met plezier helpt.'
    }
  },
  {
    color: StickyCardColors.BLUE,
    image: {
      alt: '',
      cloudinaryPublicId: 'website/common/offering-portal',
      shape: StickyCardShapes.HOUSE
    },
    key: 'portal',
    placeholders: {
      description:
        'Je energieverbruik opvolgen of je voorschot aanpassen? Dat kan je eenvoudig doen via jouw eigen My Bolt-account. Zo hoef je nooit voor verrassingen komen te staan. Facturen betalen? We maken het je zo gemakkelijk mogelijk',
      shortDescription: 'Je energieverbruik opvolgen of je voorschot aanpassen? Dat kan je eenvoudig doen via jouw eigen My Bolt-account.',
      tag: 'Online klantenzone',
      title: 'Met je online klantenzone heb je alles zelf in handen'
    }
  },
  {
    color: StickyCardColors.ORANGE,
    image: {
      alt: '',
      cloudinaryPublicId: 'website/common/offering-changemaker',
      shape: StickyCardShapes.OVERFLOWING
    },
    key: 'changemaker',
    placeholders: {
      description:
        'Als klant bij Bolt doe je meer dan alleen je huis van energie voorzien. Samen met al onze energie-opwekkers en -afnemers maak jij impact. Wist je dat een huishouden op duurzame stroom 85% minder CO2 uitstoot?',
      shortDescription: 'Samen met al onze energie-opwekkers en -afnemers maak jij impact.',
      tag: 'Changemaker community',
      title: 'Je wordt lid van onze changemaker community'
    }
  }
]
