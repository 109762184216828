export const COMMUNITY_SALES_DEFAULT_REDUCTION = 50

// These thresholds are used to overwrite the default thresholds of dayjs. They are used to determine the rounding of the relative time.
// More information can be found at: https://day.js.org/docs/en/customization/relative-time
export const DAYJS_THRESHOLDS = [
  { l: 's', r: 1 },
  { l: 'm', r: 1 },
  { l: 'mm', r: 59, d: 'minute' },
  { l: 'h', r: 1 },
  { l: 'hh', r: 23, d: 'hour' },
  { l: 'd', r: 1 },
  { l: 'dd', r: 32, d: 'day' },
  { l: 'M', r: 1 },
  { l: 'MM', r: 11, d: 'month' },
  { l: 'y', r: 1 },
  { l: 'yy', d: 'year' }
]
