import { PropsWithChildren } from 'react'
import classNames from 'classnames'
import { PredefinedTagColors, TagProps } from './types'
import styles from './Tag.module.scss'

const Tag = ({
  color = PredefinedTagColors.BRAND,
  children,
  className,
  isSmall = false,
  transparentBackground
}: PropsWithChildren<TagProps>) => {
  // Constants
  const isCustomColor = !(Object.values(PredefinedTagColors) as string[]).includes(color)

  return (
    <small
      className={classNames(styles.tag, className ?? '', {
        [styles['is-small']]: isSmall,
        [styles['with-background']]: !isCustomColor && !transparentBackground,
        [styles[color]]: !isCustomColor
      })}
      style={isCustomColor ? { color } : {}}
    >
      {children}
    </small>
  )
}

export default Tag
