export type TagProps = {
  color?: PredefinedTagColors | string
  transparentBackground?: boolean
  className?: string
  isSmall?: boolean
}

export enum PredefinedTagColors {
  BRAND = 'brand',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  PURPLE = 'purple',
  PINK = 'pink',
  LIGHT_BLUE = 'light-blue'
}
