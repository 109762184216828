import StickyCards from 'components/sections/sticky-cards/StickyCards'
import { StickyOfferingProps } from './types'
import { useTranslation } from 'next-i18next'
import { OFFERING_CARDS } from 'constants/offering'
import { memo } from 'react'
import { Heading } from '@boltenergy-be/design-system'

const StickyOffering = memo(({ title, paragraph }: StickyOfferingProps) => {
  // I18N
  const { t } = useTranslation('common')

  return (
    <StickyCards>
      <StickyCards.Header>
        <Heading as="h2" variant="h3" className="mb-300">
          {title}
        </Heading>
        {!!paragraph && <p>{paragraph}</p>}
      </StickyCards.Header>

      {OFFERING_CARDS.map((card) => (
        <StickyCards.Card
          key={card.key}
          color={card.color}
          image={{
            alt: card.image.alt,
            publicId: card.image.cloudinaryPublicId,
            shape: card.image.shape
          }}
          title={t(`common:offering.${card.key}.title`, card.placeholders.title)}
          description={t(`common:offering.${card.key}.description`, card.placeholders.description)}
        />
      ))}
    </StickyCards>
  )
})

StickyOffering.displayName = 'StickyOffering'

export default StickyOffering
